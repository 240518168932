.settingsTitle {
  color: white;
  font-size: 25px;
  padding-bottom: 0px;
  scale: 1.15;
  margin-bottom: 20px;
  text-align: center;
}

.settingForm {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    var(--main-color-light) 0%,
    var(--main-color-light) 100%
  );
  padding: 30px;
  padding-top: 10px;
  border-radius: 8px;
  width: 100%;
}

.settingsInputFieldGroupM23 {
  margin-top: 23px;
  margin-left: 5px;
  margin-right: 1px;
  margin-bottom: 1px;
  flex: 4;
}

.settingsInputFieldGroup {
  margin: 5px;
  flex: 4;
}

.settingsInputFieldGroup25 {
  margin: 5px;
  flex: 1;
}

.settingsInputFieldGroup50 {
  margin: 5px;
  flex: 2;
}

.settingInput {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 4px 5px;
  font-size: 14px;
}

.settingComboBox {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid white;
}

.Dropdown-control {
  padding: 4px 52px 4px 5px;
  border: none;
}

.settingInputDisabled {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  color: whitesmoke;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 4px 5px;
  font-size: 14px;
}

.settingsInputGroup {
  display: flex;  
  flex-wrap: wrap;
}

.settingSubmit {
  position: relative;
  background: #3E9DE9;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: -10px;
  padding: 14px;
  font-size: 16px;
  letter-spacing: 2px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 200px;
  left: 50%;
  right: 50%;
  margin-left: -100px;
  font-weight: 400;
  letter-spacing: 0.5rem;
  transition: 0.3s all;
}

.settingSubmit button[type="button"] {
  padding: 5px;
  background: #6CB4EE;
  color: white;
  letter-spacing: 0px;
  text-transform: none;
  padding: 10px;
  letter-spacing: 1px;
}

.settingSubmit:hover,
.settingSubmit button[type="button"]:hover {
  background: #6CB4EE;
  color: white;
}

.settingSubmit:active {
  transition: 0.3s all;
  top: 2px;
}

.settinglabel {
  color: #F6F6F9;
  font-size: 12px;
}

.settingsSectionTitle {
  color: whitesmoke;
  margin-top: 10px;
  margin-bottom: -8px;
  font-size: 18px;
  font-weight: 900;
  margin-left: -5px;
}

.settingsUploadLogo {
  position: relative;
  background: whitesmoke;
  color: black;
  border: none;
  font-weight: 600;
  margin-top: 10px;
  padding: 6px;
  padding-left: 15px;
  font-size: 16px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  transition: 0.3s all;
}

.settingsUploadLogo:hover,
.settingsUploadLogo button[type="button"]:hover {
  background: darkseagreen;
  color: white;
}

.settingLogoPreview {
  background: white;
  border-radius: 4px;
  margin-top: 1px;
  max-width: 200px;
  max-height: 200px;
}

.add-button:hover {
  color:  rgb(255, 255, 255, 1);
}

.remove-button {
  border: 1px solid transparent;
  background-color: transparent;
  color:  rgb(255, 255, 255, 0.6);
  font-size: 10px;
  scale: 0.7;
  filter: invert(27%) saturate(2878%) hue-rotate(346deg) brightness(104%);
  margin-left: -27px;
  margin-right: 3px;  
  margin-top: 25px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 1 );
}

.remove-button:hover {
  filter: none;
}

.add-button {
  border: 1px solid transparent;
  background-color: transparent;
  color:  rgb(255, 255, 255, 0.6);
  font-size: 20px;
  margin-left: -21px;
  margin-right: 5px;
  margin-top: -10px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 1 );
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-color-light)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid #ffffff !important;
}

.MuiInputBase-root {
  color: #ffffff !important;
 
};

.MuiInput-root {
  border-bottom: 1px solid #ffffff !important;
}

.MuiInputLabel-root {
  color: #ffffff !important;
}

.load-button {
  border: 1px solid transparent;
  background-color: transparent;
  color:  rgb(255, 255, 255, 0.6);
  font-size: 10px;
  scale: 0.7;
  margin-top: 3px;
  margin-left: -10px;
  filter: invert(27%) saturate(2878%) hue-rotate(346deg) brightness(104%);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 1 );
}

.load-button:hover {
  filter: none;
}