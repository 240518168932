.pdfblank {
  width: 210mm;
  height: 297mm;
  background: rgb(255,255,255,1);
}

.pdfblankScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(
    90deg,
    rgb(38, 56, 89, 0.9) 0%,
    rgb(38, 56, 99, 0.9) 100%
  );
  border-radius: 2px;
}

.pdfblankrightOut {
  height: 80%;
  overflow: hidden;
}


.pdfblank2Scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(
    90deg,
    rgb(38, 56, 89, 0.9) 0%,
    rgb(38, 56, 99, 0.9) 100%
  );
  border-radius: 2px;
}

.pdfblank2 {
  width: 70mm;
  height: 297mm;
}

.pdfblank3 {
  display: flex; 
  height: 67%;
}

.rect {
  float:  left;
  clear: left;
  height: 7mm;
  border-radius: 2px;
  border:1px solid #000000; 
}

.pdfDesignerCollapse {
  font-size: 20px;
  color: white;
  padding-top: 20px;
  font-weight: bolder;
}

.pdfDesignerCollapseItem {
  font-size: 14px;
  color: whitesmoke;
  padding-top: 3px;
  padding-left: 30px;
}

.pdfDesignerTitlte {
  color: white;
  font-size: 25px;
  padding-bottom: 0px;
  scale: 1.15;
  margin-bottom: 20px;
  text-align: center;
}

.pdfDesignerSave {
  position: relative;
  background: #ff6768;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 12px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 50px;
  margin-left: 20px;
  font-weight: 400;
  transition: 0.3s all;
}

.pdfDesignerSave button[type="button"] {
  padding: 5px;
  background: #516391;
  color: white;
  letter-spacing: 0px;
  text-transform: none;
  padding: 10px;
  letter-spacing: 1px;
}

.pdfDesignerSave:hover,
.pdfDesignerSave button[type="button"]:hover {
  background: #c6333d;
  color: white;
}

.pdfDesignerSave:active {
  transition: 0.3s all;
  top: 2px;
}

.formPdfDesigner {
  margin: 0 auto;
  border-radius: 2px;
}

.pdfdesigner-menu {
  display: none;
  z-index: 104;
  position: fixed;
  width: auto;
  margin-top: -17em;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 40%;
  left: 50%;
  height: 90%;
}

.pdfdesigner-open {
  display: grid;
}

.pdfDesignerAddItem {
  display: none;
  font-size: 14px;
  margin-left: 10px;
  background: #7ea36d;
  border: 0px;
  width: 20px;
  color: #ffffff;
  border-radius: 7px;
}

.pdfDesignerAddItem:hover {
  background: #507441;
  display: inline-block;
}

.pdfDesignerCollapseItem:hover .pdfDesignerAddItem {
  display: inline-block;
}
