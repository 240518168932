.navbar {
  background-image: linear-gradient(180deg, var(--main-color-light) 95%, var(--main-color) 100%);
  height: 50px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  height: 30px;
  position: fixed;
  left: 50%;
  right: 50%;
  margin-left: -100px;
  text-decoration: none;
  align-items: center;
  margin-bottom: 16px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 10vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.programm-menu {
  display: grid;
  grid-template-columns: repeat(12, auto);
  grid-gap: 0px;
  list-style: none;
  justify-content: flex-start;
  margin-left: -32px;
}

.programm {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
}


.programm-settings {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
}

.programm-liste {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
}

.right-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0px;
  list-style: none;
  width: 7vw;
  justify-content: end;
}

.navbar-icon {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
}

.vl {
  border-left: 1px solid var(--main-color);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}

.vl2 {
  border-left: 1px solid var(--main-color);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar-icon span {
  visibility: hidden;
  position: absolute;
  background: #ff6768;
  font-size: 0.8em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 4px;
  top: 90%;
  pointer-events: none;
  padding: 8px 12px;
  cursor: default;
  transform: translate3d(-50%, 0%, 0);
}

.navbar-icon:hover span {
  visibility: visible;
}

.programm:hover {
  background: var(--main-color);
}

.programm-liste:hover {
  background: var(--main-color);
}

.navbar-icon:hover {
  background: var(--main-color);
}

.programm-liste {
  display: none;
}

@media screen and (max-width: 760px) {
  .programm {
    display: none;
  }

  .programm-liste {
    display: flex;
  }

  .vl {
    visibility: hidden;
  }

  .navbar-icon {
    width: 40px;
  }

  .navbar-logo {
    margin-right: 91px;
  }
}

.programm-title {
  margin-top: 20px;
  margin-left: 65px;
}

.app-menu {
  grid-template-columns: repeat(1, auto);
  color: white;
  height: 100vh;
  width: 20rem;
  background-color: var(--main-color);
  z-index: 101;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;

  transform: translateX(-100%);
  transition: 0.3s;

  position: fixed;
  left: 0;
  top: 0;

  display: flexbox;
  grid-template-columns: repeat(1, auto);
  list-style: none;
  justify-content: center;
}

.menu-open {
  transform: translateX(0%);
}

.programm2 {
  display: flex;
  margin-top: -30px;
  align-items: center;
  text-decoration: none;
}

.programm2-menu {
  margin-top: 60px;
  margin-left: 10px;
}

.programm2-text {
  margin-left: 20px;
  margin-top: 16px;
}

#langNotSupp {
  color: red;
  content: "\26A0";
  margin-bottom: 0px;
}

.navbar-text {
  font-family: inherit;
  color: white !important;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  display: flex;
  width: 100%;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.navbar-text2 {
  font-family: inherit;
  color: white !important;
  padding-right: 150px;
  font-size: 16px;
  display: flex;
  width: 180%;
  justify-content: center;
  align-items: center;
}

.navbar-text:hover {
  color: #ff6768 !important;
}

.navbar-text2:hover {
  color: #ff6768 !important;
}
