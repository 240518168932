.setting-card {
  box-shadow: 0 2px 2px 0 var(--main-color-light);
  transition: 0.3s;
  width: 10%;
  background: linear-gradient(90deg, var(--main-color-light) 0%, var(--main-color-light) 100%);
  max-height: 300px;

  min-width: 100px;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
}

.card-box {
  display: grid;
  grid-auto-rows: 120px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(90px, 100px));
}

.setting-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.setting-container {
  text-align: center;
  color: white;
  font-size: 1px;
  min-height: 50px;
}

.setting-container h4 {
  font-size: 1000%;
  padding-top: 15px;
}
