.project-menu {
  display: none;
  z-index: 104;
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0px;
  left: 50%;
  height: -webkit-calc(100%);
  height:    -moz-calc(100%);
  height:         calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
}

.settingsInputGroup {
  display: flex;  
  flex-wrap: wrap;
}

.project-open {
  display: grid;
}

.settinglabelSmall {
  color: #F6F6F9;
  font-size: 8px;
}

.settingInputSmall {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 4px 5px;
  font-size: 10px;
}

.settingsSmallInputFieldGroup {
  margin: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex: 5;
}

.settingsSmallInputFieldGroup2 {
  margin: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex: 2;
}

.settingsSmallInputFieldGroup3 {
  margin: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 50px;
  flex: 0.5;
}