
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #17223b;
  --main-color-light: #263859;
}

body {
  margin: 0;
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-color);
}

code {
  font-family: "Sora", sans-serif;
}
