.outerfooter {
  display: grid;
  margin-top: 1rem;
  padding: 0.6rem;
  background-image: linear-gradient(360deg, var(--main-color-light) 95%, var(--main-color) 100%);
  position: fixed;
  font-size: 0.7rem;
  color: #ffffff;
  bottom: 0;
  left: 0;
  width: 100%;
}

.innerfooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

span {
  margin-left: 5px;
  margin-right: 5px;
}

.footerName {
  text-decoration: none;
  color: #ffffff;
}

.footerName:hover {
  color: #ff6768;
}

@media screen and (max-width: 450px) {
  .outerfooter {
    display: none;
  }
}
