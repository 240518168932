.title {
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  padding: 10px;
  color: #ffffff;
  opacity: 90%;
  margin-bottom: -10px;
}

.confSvg{
  scale: 1.35;
}
