.user-menu {
  display: none;
  z-index: 104;
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 55px;
  left: 50%;
  height: -webkit-calc(100% - 55px);
  height:    -moz-calc(100% - 55px);
  height:         calc(100% - 55px);
  overflow-y: auto;
  overflow-x: hidden;
}

.user-open {
  display: grid;
}
