.checkboxDefault {
  color: whitesmoke;
}

.formControlDefault {
  color: whitesmoke;
  
}

.settingsInputFieldGroupTableConf {
  margin-left: 15px;
  margin-bottom: -15px;
  flex: 1;
}
