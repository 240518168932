.rdt_TableRow {
  background: var(--main-color);
  color: #ffffff !important;
  padding: 0px !important;
  border-top: 1px solid #6b778d !important;
}

.rdt_TableRow:hover {
  background: #6b778d !important;
}

.rdt_Table {
  border-radius: 3px !important;
  border: 2px solid #6b778d !important;
}

.tablediv {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.rdt_TableHeadRow {
  background: var(--main-color) !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

.rdt_ExpanderRow {
  background: var(--main-color) !important;
  color: #ffffff !important;
  font-size: 12px !important;
}

.rdt_Pagination {
  background: var(--main-color) !important;
  color: #ffffff !important;
  padding-bottom: 35px !important;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
  padding-bottom: 2px;
}

.open {
  background: #5c85d6;
  color: #ffffff;
  border: 1px;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.printer {
  background: #6b778d;
  color: #ffffff;
  border: 1px;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-top: 0px;
  padding-right: 7px;
}

.printer:hover {
  background: #3f4b60;
}

.open:hover {
  background: #3f4b60;
}

.new {
  background: #7ea36d;
  border: 0px;
  color: #ffffff;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.new:hover {
  background: #507441;
}

.delete {
  background: #ff6768;
  color: #ffffff;
  border: 1px;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.delete:hover {
  background: #c6333d;
}

.filter:focus {
  outline: none;
}

.filter {
  background: var(--main-color);
  color: #ffffff;
  background-image: url("../../icons/search.svg");
  background-size: 8%;
  background-position: 13px 13px;
  background-repeat: no-repeat;
  font-size: 10px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin: 2px;
  border-radius: 5px;
}

.tableconf {
  background: #6b778d;
  color: #ffffff;
  border: 1px;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-top: 0px;
  padding-right: 7px;
}

.tableconf:hover {
  background: #3f4b60;
}

.closeForm {
  background: #ff6768;
  color: #ffffff;
  border: 1px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding-left: 7px;
  padding-right: 7px;
  position:absolute;
  top:0;
  right: 0%;
  margin-right: 18px;
  margin-top: 6px; 
}

.closeForm:hover {
  background: #c6333d;
}

.grouping {
  background: #d6b333;
  border: 0px;
  color: #ffffff;
  margin: 2px;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.grouping:hover {
  background: #9e8426;
}
